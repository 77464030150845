import React from "react";
import "../css/contact.css"

function Contact(){
    return(
        <div className="contact-form">
            <h1>
                Kontakta mig genom e-post
            </h1>
            <p>
                PsykologIngridLandin@gmail.com
            </p>
        </div>
    )
}
export default Contact;