import React from "react";
import "../css/english.css"

export default function English(){
    return(
        <div className="textcontainer">
            <div className="text">
                <h1>
                    For private clients
                </h1>
                <p className="undertext">
                    I offer Compassion Focussed Therapy (CFT), Schema Therapy and
                    Cognitive Behavioural Therapy (CBT) both for individual clients and
                    couples. I can help with sleep disorders, anxiety, depression and
                    relationship problems, but also with more existential questions about life.
                    Whatever you would like help with, our work together will be fitted to your
                    particular needs.
                </p>
            </div>
            <div className="text">
                <h1>
                    For professionals
                </h1>
                <p className="undertext">
                    I offer supervision and training in psychotherapy, primarily in Compassion
                    Focussed Therapy (CFT), Schema Therapy and Cognitive Behavioural
                    Therapy (CBT), but also in mindfulness. Training, long or short, can be
                    tailored to your needs.
                    I have long experience of facilitating professional development through
                    ”self-practice/self-reflection” and offer guidance and courses in this, as well
                    as training in compassion and mindfulness.
                </p>
            </div>
        </div>
    )
}